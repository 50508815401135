import {
  getAllActiveCategoriesData,
  getAllActiveScheduleData,
  getAllActiveScheduleManagerData,
  getAllSubSubCategoriesData,
  getAllTemplatesData,
  getCategoriesData,
  getCategoryTreeData,
  getCharityData,
  getDashboardData,
  getDepartmentBYIDData,
  getDepartmentData,
  getEnterpriseData,
  getEnterpriseUserData,
  getManagerData,
  getPaginatedEnterpriseUserData,
  getFCMSettingsData,
  getFlagsData,
  getIndividualUser,
  getLanguagesData,
  getManagerBYIDData,
  getPaginatedManagerData,
  getManagerLibReviewNotesData,
  getManagerLibrariesData,
  getNotificationsAllData,
  getNotificationsLimitData,
  getPackageEntpData,
  getPermissionData,
  getRemarksData,
  getReviewsBySMEIDData,
  getReviewsData,
  getRewardsData,
  getSMEAdminTitle,
  getSMECategory,
  getSMECountData,
  getSMEData,
  getSMERatingData,
  getSMESMETitle,
  getSettingsByKeyData,
  getSettingsData,
  getSubCategoriesData,
  getSubSubCategoriesData,
  getSubSubDataBySMEID,
  getSubscriptionData,
  getTagsData,
  getTemplatesByIDData,
  getUSERSBYManagerIDData,
  getUSERSByDepIDData,
  getUserLibData,
  getUserLibReviewNotesData,
  getUserLibrariesData,
  getUsersData,
} from "../utility/api/services/ManageData";
import ManageServices from "../utility/api/services/ManageServices";
import { allActiveCategoriesData } from "./AllActiveCategoryRedux";
import { charityData } from "./charityRedux";
import { currentChat } from "./chatRedux";
import { dashboardData } from "./dashboardRedux";
import { departmentData } from "./departmentRedux";
import {
  categoriesData,
  depByEntpID,
  enterpriseData,
  managerByEntpID,
  subscriptionData,
  usersDataByID,
} from "./enterpriseRedux";
import { enterpriseUserData, setTotalRows } from "./enterpriseUserRedux";
import { categoryTree } from "./extraRedux";
import { individualUserData } from "./individualRedux";
import {
  managerAssignedLibrariesData,
  managerLibNotes,
  managerLibPagination,
  managerLibReviews,
  managerLibSME,
  managerListenedLibrariesData,
  managerReadLibrariesData,
  managerReviewedLibrariesData,
  managerSkippedLibrariesData,
} from "./managerLibRedux";
import { managerData, setTotalRowsManager } from "./managerRedux";
import { handleNavigation } from "./navigation";
import {
  notificationAllData,
  notificationLimitData,
  notificationSeen,
} from "./notificationRedux";
import { packageEntpData } from "./packageEntpRedux";
import { packagesData } from "./packageRedux";
import {
  smeAllTitle,
  smeApprovedTitle,
  smeFinalTitle,
  smeRejectedTitle,
  smeWaitingTitle,
} from "./ReduxForSMETitle";
import { reviewsBySMEIDData, reviewsData } from "./reviewsRedux";
import { rewardsData } from "./rewardsRedux";
import {
  allActiveScheduleData,
  allActiveScheduleManagerData,
} from "./Scheduleredux";
import {
  AllMobileSettings,
  apiKey,
  fcmSettings,
  languageData,
  mobileSettingsAnn,
  emailTemplateData,
  // new
  reminder4Data,
  mailsData,
  mobileSettingsInst,
  settingsData,
  websiteSettingsLogo,
} from "./settingsredux";
import {
  smeApprovedAdminTitleData,
  smeAllAdminTitleData,
  smeWaitingAdminTitleData,
  smeRejectedAdminTitleData,
  smeFinalAdminTitleData,
  adminAllTutorialPagination,
} from "./smeAdminTitleRedux";
import {
  allSubSubCatData,
  averageRatingData,
  smeCategoryData,
  smeData,
  subCategoryData,
  subSubCatData,
} from "./smeRedux";
import {
  smeAllSMETitleData,
  smeApprovedSMETitleData,
  smeDraftedSMETitleData,
  smeFinalSMETitleData,
  smeRejectedSMETitleData,
  smeRemarks,
  smesmeCount,
  smeWaitingSMETitleData,
} from "./smeSMETitleRedux";
import { flagsData, tagsData } from "./tagsRedux";
import { templatesAll, templatesByID } from "./templatesRedux";
import {
  userAssignedLibrariesData,
  userLibNotes,
  userLibPagination,
  userLibReviews,
  userLibSME,
  userListenedLibrariesData,
  userReadLibrariesData,
  userReviewedLibrariesData,
  userSkippedLibrariesData,
} from "./userLibrariesRedux";
import { userPermissions, usersData } from "./userRedux";
import toast from "react-hot-toast";

const userRoutingHandler = (payload) => {
  return (dispatch) => {
    getUserPermissions(payload).then((res) => {
      console.log("res", res);
      const data = res?.data?.map((e, i) => ({ ...e, sn: i + 1 }));
      console.log("userPermissionHandler : ", data);
      if (data) {
        dispatch(userPermissionsData(data));
        dispatch(handleNavigation(data));
      }
    });
  };
};

const dashboardHandler = (payload) => {
  return (dispatch) => {
    getDashboardData(payload).then((res) => {
      console.log("res:", payload);
      // const data = res?.data?.list?.map((e, i) => ({ ...e, sn: i + 1 }));
      const data = res;
      if (data) {
        console.log("called");
        dispatch(dashboardData(data));
      }
    });
  };
};
const userHandler = () => {
  return (dispatch) => {
    getUsersData().then((res) => {
      console.log("res", res);
      const data = res?.data?.list?.map((e, i) => ({ ...e, sn: i + 1 }));
      if (data) {
        dispatch(usersData(data));
      }
    });
  };
};
const enterpriseHandler = () => {
  return (dispatch) => {
    getEnterpriseData().then((res) => {
      console.log("res", res);
      const data = res?.data?.map((e, i) => ({ ...e, sn: i + 1 }));
      if (data) {
        dispatch(enterpriseData(data));
      }
    });
  };
};
const categoriesHandler = () => {
  return (dispatch) => {
    getCategoriesData().then((res) => {
      console.log("res", res);
      const data = res?.data?.map((e, i) => ({ ...e, sn: i + 1 }));
      if (data) {
        dispatch(categoriesData(data));
      }
    });
  };
};
const subscriptionHandler = () => {
  return (dispatch) => {
    getSubscriptionData().then((res) => {
      console.log("res", res);
      const data = res?.data
        ?.filter(
          (a) =>
            a.subscription_model === "individual_user" ||
            a.subscription_model === "individual" ||
            a.subscription_model === "applied_to_inv"
        )
        ?.map((e, i) => ({ ...e, sn: i + 1 }));
      if (data) {
        dispatch(packagesData(data));
      }
    });
  };
};
const packageEntpHandler = () => {
  return (dispatch) => {
    getPackageEntpData().then((res) => {
      console.log("res", res);
      const data = res?.data
        ?.filter((a) => a.subscription_model == "enterprise")
        ?.map((e, i) => ({ ...e, sn: i + 1 }));
      if (data) {
        dispatch(packageEntpData(data));
      }
    });
  };
};
const departmentHandler = () => {
  return (dispatch) => {
    getDepartmentData().then((res) => {
      console.log("res12", res);
      const data = res?.data?.map((e, i) => ({ ...e, sn: i + 1 }));
      if (data) {
        dispatch(departmentData(data));
      }
    });
  };
};
export const managerHandler = () => {
  return (dispatch) => {
    getManagerData().then((res) => {
      console.log("res12", res);
      const data = res?.data?.map((e, i) => ({ ...e, sn: i + 1 }));
      if (data) {
        dispatch(managerData(data));
      }
    });
  };
};
const enterpriseUserHandler = () => {
  return (dispatch) => {
    getEnterpriseUserData().then((res) => {
      console.log("res12", res);
      const data = res?.data?.map((e, i) => ({ ...e, sn: i + 1 }));
      if (data) {
        dispatch(enterpriseUserData(data));
      }
    });
  };
};

export const managerHandlerPaginated = (pageNo, pageSize, searchTerm = "") => {
  return async (dispatch) => {
    try {
      // Fetch data with pagination and search term
      const res = await getPaginatedManagerData(pageNo, pageSize, searchTerm);

      if (res?.status === 1) {
        const data = res.data; // Extract the user data
        const totalRows = res.total; // Extract total rows

        // Dispatch user data and total rows to Redux
        dispatch(managerData(data)); // Users array
        dispatch(setTotalRowsManager(totalRows)); // Total records
      } else {
        console.log("Error in response");
      }
    } catch (error) {
      console.error("Error fetching paginated data:", error);
    }
  };
};

// saad new user paginated api
export const enterpriseUserHandlerPaginated = (
  pageNo,
  pageSize,
  searchTerm = ""
) => {
  return async (dispatch) => {
    try {
      console.log("INSIDE");
      const res = await getPaginatedEnterpriseUserData(
        pageNo,
        pageSize,
        searchTerm
      );

      if (res?.status === 1) {
        const users = res.data; // Extract the user data
        const totalRows = res.pagination?.totalRecords || 0; // Extract total rows
        console.log("Total Rows to Dispatch:", totalRows);

        // Dispatch user data and total rows to Redux
        dispatch(enterpriseUserData(users)); // Users array
        dispatch(setTotalRows(totalRows)); // Total records
        console.log("Dispatched Total Rows:", totalRows);
      } else {
        console.error("Failed to fetch users:", res?.message);
      }
    } catch (error) {
      console.error("Error fetching paginated enterprise users:", error);
    }
  };
};

const reviewsHandler = () => {
  return (dispatch) => {
    getReviewsData().then((res) => {
      console.log("res12", res);
      const data = res?.data?.map((e, i) => ({ ...e, sn: i + 1 }));
      if (data) {
        dispatch(reviewsData(data));
      }
    });
  };
};
const smeHandler = () => {
  return (dispatch) => {
    getSMEData().then((res) => {
      console.log("res12", res);
      const data = res?.data?.map((e, i) => ({ ...e, sn: i + 1 }));
      if (data) {
        dispatch(smeData(data));
      }
    });
  };
};
const userPermissionHandler = (payload) => {
  return (dispatch) => {
    getPermissionData(payload).then((res) => {
      console.log("res", res);
      if (res.status == 0) {
        toast.error(res.message);
      } else {
        // const data = res?.data?.map((e, i) => ({ ...e, sn: i + 1 }));
        const data = res?.data;
        if (data) {
          dispatch(userPermissions(data));
        }
      }
    });
  };
};
const getReviewsBySMEIDHandler = (payload) => {
  return (dispatch) => {
    getReviewsBySMEIDData(payload).then((res) => {
      console.log("res", res);
      if (res.status == 0) {
        toast.error(res.message);
      } else {
        const data = res?.data?.map((e, i) => ({ ...e, sn: i + 1 }));
        if (data) {
          dispatch(reviewsData(data));
        }
      }
    });
  };
};
const getDepByEntpIDHandler = (payload) => {
  return (dispatch) => {
    getDepartmentBYIDData(payload).then((res) => {
      console.log("res", res);
      if (res.status == 0) {
        toast.error(res.message);
      } else {
        const data = res?.data?.map((e, i) => ({ ...e, sn: i + 1 }));
        if (data) {
          dispatch(depByEntpID(data));
        }
      }
    });
  };
};
const getManagerBYIDHandler = (payload) => {
  return (dispatch) => {
    getManagerBYIDData(payload).then((res) => {
      console.log("res", res);
      if (res.status == 0) {
        toast.error(res.message);
      } else {
        const data = res?.data?.map((e, i) => ({ ...e, sn: i + 1 }));
        if (data) {
          dispatch(managerByEntpID(data));
        }
      }
    });
  };
};
const getUSERSBYManagerIDHandler = (payload) => {
  return (dispatch) => {
    getUSERSBYManagerIDData(payload).then((res) => {
      console.log("res===", res);
      if (res.status == 0) {
        toast.error(res.message);
      } else {
        const data = res?.data?.map((e, i) => ({ ...e, sn: i + 1 }));
        if (data) {
          dispatch(usersDataByID(data));
        }
      }
    });
  };
};
const getUSERSBYDepIDHandler = (payload) => {
  return (dispatch) => {
    getUSERSByDepIDData(payload).then((res) => {
      console.log("res", res);
      if (res.status == 0) {
        toast.error(res.message);
      } else {
        const data = res?.data?.map((e, i) => ({ ...e, sn: i + 1 }));
        if (data) {
          dispatch(usersDataByID(data));
        }
      }
    });
  };
};
const getSMERatingHandler = (payload) => {
  return (dispatch) => {
    getSMERatingData(payload).then((res) => {
      console.log("res", res);
      if (res.status == 0) {
        toast.error(res.message);
      } else {
        const data = res?.data?.map((e, i) => ({ ...e, sn: i + 1 }));
        if (data) {
          dispatch(averageRatingData(data));
        }
      }
    });
  };
};

const getIndividualHandler = () => {
  return (dispatch) => {
    getIndividualUser().then((res) => {
      console.log("res", res);
      if (res.status == 0) {
        toast.error(res.message);
      } else {
        const data = res?.data?.map((e, i) => ({ ...e, sn: i + 1 }));
        if (data) {
          dispatch(individualUserData(data));
        }
      }
    });
  };
};
const getSMECategoryHandler = () => {
  return (dispatch) => {
    getSMECategory().then((res) => {
      console.log("res", res);
      if (res.status == 0) {
        toast.error(res.message);
      } else {
        const data = res?.data?.map((e, i) => ({ ...e, sn: i + 1 }));
        if (data) {
          dispatch(smeCategoryData(data));
        }
      }
    });
  };
};
const getSMEALLAdminTitleHandler = (payload) => {
  return (dispatch) => {
    getSMEAdminTitle(payload).then((res) => {
      console.log("res===", res);

      if (res.status == 0) {
        toast.error(res.message);
      } else {
        if (res) {
          const data = res?.data?.map((e, i) => ({ ...e, sn: i + 1 }));
          if (data) {
            dispatch(smeAllAdminTitleData(data));
            //  dispatch(adminTutorialPagination(newObj));
            dispatch(
              adminAllTutorialPagination({
                ...payload,
                totalPages: res.totalPages,
                total_rows: res.total_rows,
              })
            );
          }
        }
      }
    });
  };
};

const getSMERejectedAdminTitleHandler = (payload) => {
  return (dispatch) => {
    getSMEAdminTitle(payload).then((res) => {
      console.log("res", res);
      if (res.status == 0) {
        toast.error(res.message);
      } else {
        if (res) {
          const data = res?.data?.map((e, i) => ({ ...e, sn: i + 1 }));
          if (data) {
            dispatch(smeRejectedAdminTitleData(data));
            dispatch(
              adminAllTutorialPagination({
                ...payload,
                totalPages: res.totalPages,
                total_rows: res.total_rows,
              })
            );
          }
        }
      }
    });
  };
};
const getSMEApprovedAdminTitleHandler = (payload) => {
  return (dispatch) => {
    getSMEAdminTitle(payload).then((res) => {
      console.log("res", res);
      // if(res.status == 0){
      //   toast.error(res.message)
      // }
      // else{
      if (res) {
        const data = res?.data?.map((e, i) => ({ ...e, sn: i + 1 }));
        if (data) {
          dispatch(smeApprovedAdminTitleData(data));
          dispatch(
            adminAllTutorialPagination({
              ...payload,
              totalPages: res.totalPages,
              total_rows: res.total_rows,
            })
          );
          //    dispatch(smeWaitingAdminTitleData(data))
        }
      }
      // }
    });
  };
};
const getSMEWaitingAdminTitleHandler = (payload) => {
  return (dispatch) => {
    getSMEAdminTitle(payload).then((res) => {
      console.log("res", res);
      if (res.status == 0) {
        toast.error(res.message);
      } else {
        if (res) {
          const data = res?.data?.map((e, i) => ({ ...e, sn: i + 1 }));
          if (data) {
            dispatch(smeWaitingAdminTitleData(data));
            dispatch(
              adminAllTutorialPagination({
                ...payload,
                totalPages: res.totalPages,
                total_rows: res.total_rows,
              })
            );
          }
        }
      }
    });
  };
};
const getSMEFinalAdminTitleHandler = (payload) => {
  return (dispatch) => {
    getSMEAdminTitle(payload).then((res) => {
      console.log("res", res);
      if (res.status == 0) {
        toast.error(res.message);
      } else {
        if (res) {
          const data = res?.data?.map((e, i) => ({ ...e, sn: i + 1 }));
          if (data) {
            dispatch(smeFinalAdminTitleData(data));
            dispatch(
              adminAllTutorialPagination({
                ...payload,
                totalPages: res.totalPages,
                total_rows: res.total_rows,
              })
            );
          }
        }
      }
    });
  };
};
const getSMEALLHandler = (payload) => {
  return (dispatch) => {
    getSMESMETitle(payload).then((res) => {
      console.log("res", res);
      if (res.status == 0) {
        toast.error(res.message);
      } else {
        const data = res?.data
          ?.filter((item) => item.status !== "drafted")
          ?.map((e, i) => ({ ...e, sn: i + 1 }));
        if (data) {
          dispatch(smeAllTitle(data));
        }
      }
    });
  };
};
const getSMERejectedHandler = (payload) => {
  return (dispatch) => {
    getSMESMETitle(payload).then((res) => {
      console.log("res", res);
      if (res.status == 0) {
        toast.error(res.message);
      } else {
        const data = res?.data?.map((e, i) => ({ ...e, sn: i + 1 }));
        if (data) {
          dispatch(smeRejectedTitle(data));
        }
      }
    });
  };
};
const getSMEApprovedHandler = (payload) => {
  return (dispatch) => {
    getSMESMETitle(payload).then((res) => {
      console.log("res", res);
      if (res.status == 0) {
        toast.error(res.message);
      } else {
        const data = res?.data?.map((e, i) => ({ ...e, sn: i + 1 }));
        if (data) {
          dispatch(smeApprovedTitle(data));
        }
      }
    });
  };
};
const getSMEWaitingHandler = (payload) => {
  return (dispatch) => {
    getSMESMETitle(payload).then((res) => {
      console.log("res", res);
      if (res.status == 0) {
        toast.error(res.message);
      } else {
        const data = res?.data?.map((e, i) => ({ ...e, sn: i + 1 }));
        if (data) {
          dispatch(smeWaitingTitle(data));
        }
      }
    });
  };
};
const getSMEFinalHandler = (payload) => {
  return (dispatch) => {
    getSMESMETitle(payload).then((res) => {
      console.log("res", res);
      if (res.status == 0) {
        toast.error(res.message);
      } else {
        const data = res?.data?.map((e, i) => ({ ...e, sn: i + 1 }));
        if (data) {
          dispatch(smeFinalTitle(data));
        }
      }
    });
  };
};

const getSMEALLSMETitleHandler = (payload) => {
  return (dispatch) => {
    getSMESMETitle(payload).then((res) => {
      console.log("res", res);
      if (res.status == 0) {
        toast.error(res.message);
      } else {
        const data = res?.data?.map((e, i) => ({ ...e, sn: i + 1 }));
        if (data) {
          dispatch(smeAllSMETitleData(data));
        }
      }
    });
  };
};
const getSMERejectedSMETitleHandler = (payload) => {
  return (dispatch) => {
    getSMESMETitle(payload).then((res) => {
      console.log("res", res);
      if (res.status == 0) {
        toast.error(res.message);
      } else {
        const data = res?.data?.map((e, i) => ({ ...e, sn: i + 1 }));
        if (data) {
          dispatch(smeRejectedSMETitleData(data));
        }
      }
    });
  };
};
const getSMEApprovedSMETitleHandler = (payload) => {
  return (dispatch) => {
    getSMESMETitle(payload).then((res) => {
      console.log("res", res);
      if (res.status == 0) {
        toast.error(res.message);
      } else {
        const data = res?.data?.map((e, i) => ({ ...e, sn: i + 1 }));
        if (data) {
          dispatch(smeApprovedSMETitleData(data));
        }
      }
    });
  };
};
const getSMEWaitingSMETitleHandler = (payload) => {
  return (dispatch) => {
    getSMESMETitle(payload).then((res) => {
      console.log("res", res);
      if (res.status == 0) {
        toast.error(res.message);
      } else {
        const data = res?.data?.map((e, i) => ({ ...e, sn: i + 1 }));
        if (data) {
          dispatch(smeWaitingSMETitleData(data));
        }
      }
    });
  };
};

const getSMEFinalSMETitleHandler = (payload) => {
  return (dispatch) => {
    getSMESMETitle(payload).then((res) => {
      console.log("res", res);
      if (res.status == 0) {
        toast.error(res.message);
      } else {
        const data = res?.data?.map((e, i) => ({ ...e, sn: i + 1 }));
        if (data) {
          dispatch(smeFinalSMETitleData(data));
        }
      }
    });
  };
};

const getSMEDraftedSMETitleHandler = (payload) => {
  return (dispatch) => {
    getSMESMETitle(payload).then((res) => {
      console.log("res", res);
      if (res.status == 0) {
        toast.error(res.message);
      } else {
        const data = res?.data?.map((e, i) => ({ ...e, sn: i + 1 }));
        if (data) {
          dispatch(smeDraftedSMETitleData(data));
        }
      }
    });
  };
};
const getRemarksHandler = (payload) => {
  return (dispatch) => {
    getRemarksData(payload).then((res) => {
      console.log("res", res);
      if (res.status == 0) {
        toast.error(res.message);
      } else {
        const data = res?.data?.map((e, i) => ({ ...e, sn: i + 1 }));
        if (data) {
          dispatch(smeRemarks(data));
        }
      }
    });
  };
};

const getSettingsHandler = () => {
  return (dispatch) => {
    getSettingsData().then((res) => {
      console.log("res", res);
      if (res.status == 0) {
        toast.error(res.message);
      } else {
        const data = res?.data?.map((e, i) => ({ ...e, sn: i + 1 }));
        console.log("Hello");
        if (data) {
          dispatch(settingsData(data));
          dispatch(AllMobileSettings(data));
        }
      }
    });
  };
};
const getSettingsByKeyHandler = (payload) => {
  return (dispatch) => {
    getSettingsByKeyData(payload).then((res) => {
      console.log("res===", res);
      if (res.status == 0) {
        toast.error(res.message);
      } else {
        const data = res?.data;
        if (data && payload == "google_api_key") {
          dispatch(apiKey(data));
        }
        if (data && payload === "mobile_app_logo") {
          dispatch(websiteSettingsLogo(data));
        }
        if (data && payload === "website_logo") {
          dispatch(websiteSettingsLogo(data));
        }
        if (data && payload === "login_instruction") {
          dispatch(mobileSettingsInst(data));
        }
        if (data && payload === "mobile_announcement") {
          dispatch(mobileSettingsAnn(data));
        }
        if (data && payload === "mobile_setting") {
          dispatch(AllMobileSettings(data));
        }
        if (data && payload === "reminder1") {
          dispatch(emailTemplateData(data));
        }
        // new
        if (data && payload === "reminder4") {
          dispatch(reminder4Data(data));
        }
        // new
        if (data && payload == "mails") {
          dispatch(mailsData(data));
        }
      }
    });
  };
};

const getRewardsHandler = () => {
  return (dispatch) => {
    getRewardsData().then((res) => {
      console.log("res", res);
      // if(res.status == 0){
      //   toast.error(res.message)
      // }
      // else{
      const data = res?.data?.map((e, i) => ({ ...e, sn: i + 1 }));
      if (data) {
        dispatch(rewardsData(data));
      }

      // }
    });
  };
};
const getLanguageHandler = () => {
  return (dispatch) => {
    getLanguagesData().then((res) => {
      console.log("res", res);
      if (res.status == 0) {
        toast.error(res.message);
      } else {
        const data = res?.data?.languages?.map((e, i) => ({ ...e, sn: i + 1 }));
        if (data) {
          dispatch(languageData(data));
        }
      }
    });
  };
};
const getCharityHandler = () => {
  return (dispatch) => {
    getCharityData().then((res) => {
      console.log("res", res);
      if (res.status == 0) {
        toast.error(res.message);
      } else {
        const data = res?.data?.map((e, i) => ({ ...e, sn: i + 1 }));
        if (data) {
          dispatch(charityData(data));
        }
      }
    });
  };
};
const getUserAssignedLibrariesHandler = (payload) => {
  return (dispatch) => {
    getUserLibrariesData(payload).then((res) => {
      console.log("res", res);
      if (res && res.totalPages) {
        const data = res?.data?.map((e, i) => ({ ...e, sn: i + 1 }));
        if (data) {
          dispatch(userAssignedLibrariesData(data));
          dispatch(
            userLibPagination({
              ...payload,
              totalPages: res.totalPages,
              total_rows: res.total_rows,
            })
          );
        }
      }
    });
  };
};

const getManagerAssignedLibrariesHandler = (payload) => {
  return (dispatch) => {
    getManagerLibrariesData(payload).then((res) => {
      console.log("res", res);
      if (res && res.totalPages) {
        const data = res?.data?.map((e, i) => ({ ...e, sn: i + 1 }));
        if (data) {
          dispatch(managerAssignedLibrariesData(data));
          dispatch(
            managerLibPagination({
              ...payload,
              totalPages: res.totalPages,
              total_rows: res.total_rows,
            })
          );
        }
      }
    });
  };
};

const getUserListenedLibrariesHandler = (payload) => {
  return (dispatch) => {
    getUserLibrariesData(payload).then((res) => {
      console.log("res", res);
      if (res && res.totalPages) {
        const data = res?.data?.map((e, i) => ({ ...e, sn: i + 1 }));
        if (data) {
          dispatch(userListenedLibrariesData(data));
          dispatch(
            userLibPagination({
              ...payload,
              totalPages: res.totalPages,
              total_rows: res.total_rows,
            })
          );
        }
      }
    });
  };
};
const getManagerListenedLibrariesHandler = (payload) => {
  return (dispatch) => {
    getManagerLibrariesData(payload).then((res) => {
      console.log("res", res);
      if (res && res.totalPages) {
        const data = res?.data?.map((e, i) => ({ ...e, sn: i + 1 }));
        if (data) {
          dispatch(managerListenedLibrariesData(data));
          dispatch(
            managerLibPagination({
              ...payload,
              totalPages: res.totalPages,
              total_rows: res.total_rows,
            })
          );
        }
      }
    });
  };
};
const getUserSkippedLibrariesHandler = (payload) => {
  return (dispatch) => {
    getUserLibrariesData(payload).then((res) => {
      console.log("res", res);
      if (res && res.totalPages) {
        const data = res?.data?.map((e, i) => ({ ...e, sn: i + 1 }));
        if (data) {
          dispatch(userSkippedLibrariesData(data));
          dispatch(
            userLibPagination({
              ...payload,
              totalPages: res.totalPages,
              total_rows: res.total_rows,
            })
          );
        }
      }
    });
  };
};
const getManagerSkippedLibrariesHandler = (payload) => {
  return (dispatch) => {
    getManagerLibrariesData(payload).then((res) => {
      console.log("res", res);
      if (res && res.totalPages) {
        const data = res?.data?.map((e, i) => ({ ...e, sn: i + 1 }));
        if (data) {
          dispatch(managerSkippedLibrariesData(data));
          dispatch(
            managerLibPagination({
              ...payload,
              totalPages: res.totalPages,
              total_rows: res.total_rows,
            })
          );
        }
      }
    });
  };
};
const getUserReadLibrariesHandler = (payload) => {
  return (dispatch) => {
    getUserLibrariesData(payload).then((res) => {
      console.log("res", res);
      if (res && res.totalPages) {
        const data = res?.data?.map((e, i) => ({ ...e, sn: i + 1 }));
        if (data) {
          dispatch(userReadLibrariesData(data));
          dispatch(
            userLibPagination({
              ...payload,
              totalPages: res.totalPages,
              total_rows: res.total_rows,
            })
          );
        }
      }
    });
  };
};
const getManagerReadLibrariesHandler = (payload) => {
  return (dispatch) => {
    getManagerLibrariesData(payload).then((res) => {
      console.log("res", res);
      if (res && res.totalPages) {
        const data = res?.data?.map((e, i) => ({ ...e, sn: i + 1 }));
        if (data) {
          dispatch(managerReadLibrariesData(data));
          dispatch(
            managerLibPagination({
              ...payload,
              totalPages: res.totalPages,
              total_rows: res.total_rows,
            })
          );
        }
      }
    });
  };
};

const getUserReviewedLibrariesHandler = (payload) => {
  return (dispatch) => {
    getUserLibrariesData(payload).then((res) => {
      console.log("res", res);
      if (res && res.totalPages) {
        const data = res?.data?.map((e, i) => ({ ...e, sn: i + 1 }));
        if (data) {
          dispatch(userReviewedLibrariesData(data));
          dispatch(
            userLibPagination({
              ...payload,
              totalPages: res.totalPages,
              total_rows: res.total_rows,
            })
          );
        }
      }
    });
  };
};
const getManagerReviewedLibrariesHandler = (payload) => {
  return (dispatch) => {
    getManagerLibrariesData(payload).then((res) => {
      console.log("res", res);
      if (res && res.totalPages) {
        const data = res?.data?.map((e, i) => ({ ...e, sn: i + 1 }));
        if (data) {
          dispatch(managerReviewedLibrariesData(data));
          dispatch(
            managerLibPagination({
              ...payload,
              totalPages: res.totalPages,
              total_rows: res.total_rows,
            })
          );
        }
      }
    });
  };
};

const getSubCategoriesHandler = (payload) => {
  return (dispatch) => {
    getSubCategoriesData(payload).then((res) => {
      console.log("res", res);
      if (res.status == 0) {
        toast.error(res.message);
      } else {
        const data = res?.data?.map((e, i) => ({ ...e, sn: i + 1 }));
        if (data) {
          dispatch(subCategoryData(data));
        }
      }
    });
  };
};

const getSubSubBySMEIDHandler = (payload) => {
  return (dispatch) => {
    getSubSubDataBySMEID(payload).then((res) => {
      console.log("res", res);
      if (res.status == 0) {
        toast.error(res.message);
      } else {
        const data = res?.data?.map((e, i) => ({ ...e, sn: i + 1 }));
        if (data) {
          dispatch(allSubSubCatData(data));
        }
      }
    });
  };
};
const getSubSubCategoriesHandler = (payload) => {
  return (dispatch) => {
    getSubSubCategoriesData(payload).then((res) => {
      console.log("res", res);
      if (res.status == 0) {
        toast.error(res.message);
      } else {
        const data = res?.data?.map((e, i) => ({ ...e, sn: i + 1 }));
        if (data) {
          dispatch(subSubCatData(data));
        }
      }
    });
  };
};
const getAllSubSubCategoriesHandler = () => {
  return (dispatch) => {
    getAllSubSubCategoriesData().then((res) => {
      console.log("res", res);
      if (res.status == 0) {
        toast.error(res.message);
      } else {
        const data = res?.data?.map((e, i) => ({ ...e, sn: i + 1 }));
        if (data) {
          dispatch(allSubSubCatData(data));
        }
      }
    });
  };
};
const getAllActiveCategoriesHandler = () => {
  return (dispatch) => {
    getAllActiveCategoriesData().then((res) => {
      console.log("res", res);
      // if(res.status == 0){
      //   toast.error(res.message)
      // }
      // else{
      const data = res?.data?.map((e, i) => ({ ...e, sn: i + 1 }));
      if (data) {
        dispatch(allActiveCategoriesData(data));
      }

      // }
    });
  };
};
const getTagsHandler = () => {
  return (dispatch) => {
    getTagsData().then((res) => {
      console.log("res", res);
      // if(res.status == 0){
      //   toast.error(res.message)
      // }
      // else{
      const data = res?.data?.map((e, i) => ({ ...e, sn: i + 1 }));
      if (data) {
        dispatch(tagsData(data));
      }

      // }
    });
  };
};
const getFlagsHandler = () => {
  return (dispatch) => {
    getFlagsData().then((res) => {
      console.log("res", res);
      // if(res.status == 0){
      //   toast.error(res.message)
      // }
      // else{
      const data = res?.data;
      if (data) {
        dispatch(flagsData(data));
      }

      // }
    });
  };
};
const getUserLibReviewsHandler = (payload) => {
  return (dispatch) => {
    getUserLibReviewNotesData(payload).then((res) => {
      console.log("res", res);
      // if(res.status == 0){
      //   toast.error(res.message)
      // }
      // else{

      const reviews = res?.data?.review;
      const SME = res?.data?.sme;

      if (res) {
        dispatch(userLibReviews(reviews));
        dispatch(userLibSME(SME));
      }

      // }
    });
  };
};
const getManagerLibReviewsHandler = (payload) => {
  return (dispatch) => {
    getManagerLibReviewNotesData(payload).then((res) => {
      console.log("res", res);
      // if(res.status == 0){
      //   toast.error(res.message)
      // }
      // else{
      const reviews = res?.data?.review;
      const SME = res?.data?.sme;

      if (res) {
        dispatch(managerLibReviews(reviews));
        dispatch(managerLibSME(SME));
      }

      // }
    });
  };
};
const getUserLibNotesHandler = (payload) => {
  return (dispatch) => {
    getUserLibReviewNotesData(payload).then((res) => {
      console.log("res", res);
      // if(res.status == 0){
      //   toast.error(res.message)
      // }
      // else{
      const notes = res?.data?.notes;
      if (notes) {
        dispatch(userLibNotes(notes));
      }

      // }
    });
  };
};
const getManagerLibNotesHandler = (payload) => {
  return (dispatch) => {
    getManagerLibReviewNotesData(payload).then((res) => {
      console.log("res", res);
      // if(res.status == 0){
      //   toast.error(res.message)
      // }
      // else{
      const notes = res?.data?.notes;
      if (notes) {
        dispatch(managerLibNotes(notes));
      }

      // }
    });
  };
};
const getAllActiveScheduleHandler = (payload) => {
  return (dispatch) => {
    getAllActiveScheduleData(payload).then((res) => {
      console.log("res", res);
      // if(res.status == 0){
      //   toast.error(res.message)
      // }
      // else{
      const notes = res?.data;
      if (notes) {
        dispatch(allActiveScheduleData(notes));
      }

      // }
    });
  };
};
const getAllActiveScheduleManagerHandler = (payload) => {
  return (dispatch) => {
    getAllActiveScheduleManagerData(payload).then((res) => {
      console.log("res", res);
      // if(res.status == 0){
      //   toast.error(res.message)
      // }
      // else{
      const notes = res?.data;
      if (notes) {
        dispatch(allActiveScheduleManagerData(notes));
      }

      // }
    });
  };
};
const getAllTemplatesHandler = () => {
  return (dispatch) => {
    getAllTemplatesData().then((res) => {
      console.log("res", res);
      // if(res.status == 0){
      //   toast.error(res.message)
      // }
      // else{
      const notes = res?.data?.map((e, i) => ({ ...e, sn: i + 1 }));
      // const notes = res?.data?.filter(x => x.status == 'active' || x.status == "Active")?.map((e, i) => ({ ...e, sn: i + 1 }));
      if (notes) {
        dispatch(templatesAll(notes));
      }

      // }
    });
  };
};
const getTemplatesByIDHandler = (payload) => {
  return (dispatch) => {
    getTemplatesByIDData(payload).then((res) => {
      console.log("res", res);
      // if(res.status == 0){
      //   toast.error(res.message)
      // }
      // else{
      const notes = res?.data;
      if (notes) {
        dispatch(templatesByID(notes));
      }

      // }
    });
  };
};
const getSMECountDataHandler = (payload) => {
  return (dispatch) => {
    getSMECountData(payload).then((res) => {
      console.log("res", res);
      // if(res.status == 0){
      //   toast.error(res.message)
      // }
      // else{
      const notes = res?.data;
      if (notes) {
        dispatch(smesmeCount(notes));
      }

      // }
    });
  };
};
const getCategoryTreeHandler = (payload) => {
  return (dispatch) => {
    getCategoryTreeData(payload).then((res) => {
      console.log("res", res);
      // if(res.status == 0){
      //   toast.error(res.message)
      // }
      // else{
      const notes = res?.data?.map((e, i) => ({ ...e, sn: i + 1 }));
      if (notes) {
        dispatch(categoryTree(notes));
      }

      // }
    });
  };
};
const getNotificationsAllHandler = (payload) => {
  return (dispatch) => {
    getNotificationsAllData(payload).then((res) => {
      console.log("res", res);
      // if(res.status == 0){
      //   toast.error(res.message)
      // }
      // else{
      const notes = res?.data?.map((e, i) => ({ ...e, sn: i + 1 }));
      // const notes = res?.data
      if (notes) {
        dispatch(notificationAllData(notes));
      }

      // }
    });
  };
};
const getNotificationsLimitHandler = (payload) => {
  return (dispatch) => {
    getNotificationsLimitData(payload).then((res) => {
      console.log("res", res);

      // const notes = res?.data?.map((e, i) => ({ ...e, sn: i + 1 }));
      const notificationData = res?.data;
      const seen = res?.unseen;
      if (notificationData) {
        dispatch(notificationLimitData(notificationData));
        dispatch(notificationSeen(seen));
      }
    });
  };
};

const getFCMSettingsHandler = () => {
  return (dispatch) => {
    getFCMSettingsData().then((res) => {
      console.log("res", res);

      const data = res?.map((e, i) => ({ ...e, sn: i + 1 }));
      if (data) {
        dispatch(fcmSettings(data));
      }
    });
  };
};

//   export const getChatUserList=async()=>{

//     try{

//         const userData = JSON.parse(localStorage.getItem('userData'))
//         const response = await NewsFeedService.postGetUserInboxList({userId:userData.user_id})

//         if(response){

//             const result = response.data.data.data

//             setUsersList(result)

//         }

//     }catch(err){
//         console.log('err : ',err)
//     }

// }

/////////////////////////////////////////////////////
/////////////////////////////////////////////////////
/////////////////////////////////////////////////////
////////////////////  CHAT  ////////////////////////
/////////////////////////////////////////////////////
/////////////////////////////////////////////////////

// const getUserChat=async(userId)=>{

//     try{

//         const payload={

//             "receiverId":userId,
//             "userId":JSON.parse(localStorage.getItem('userData')).user_id

//         }

//         const response = await ManageServices.getUserChatById(payload)

//         if(response){

//             const result = response.data.chat
//             dispatch(usersList(arr))

//         }

//     }catch(err){

//         console.log(err)
//     }
// }

// const sendMessage=async(payload,setLoading,currentChat1,setMessage,mediaList,setMediaList)=>{

//     try{

//         const authstore = store.getState().auth
//         const socket = authstore.socket

//         setLoading(true)

//         const arr=[...currentChat1]
//         const userData = JSON.parse(localStorage.getItem('userData'))
//         const result = await ManageServices.postSendMessage(payload)

//         if(result){

//             const id = result.data.data.insertId

//             const obj={

//                 sender:payload.get('sender'),
//                 receiver:payload.get('receiver'),
//                 message:payload.get('message'),
//                 origin:'from_me',
//                 profile_image_url:userData.profile_image_url,
//                 name: userData.full_name,
//                 id,
//                 chatid:id,
//                 media:mediaList

//             }

//             arr.push(obj)
//             dispatch(currentChat(arr))
//             setLoading(false)
//             setMessage('')
//             setMediaList([])

//             socket.emit('message',obj)

//             console.log(result)
//         }

//     }catch(err){

//         console.log(err)

//     }
// }

// const pushSocketMessageToChat=(message)=>{

//     const storeData = store.getState().chatReducer
//     const currentChat1 = JSON.parse(JSON.stringify(storeData.currentChat))

//     const findData = currentChat1.find(x=>x.id==message.id)

//     if(!findData){

//         currentChat1.push(message)

//              dispatch(currentChat(currentChat1))

//     }

// }

export default {
  getNotificationsAllHandler,
  getNotificationsLimitHandler,

  // getUserChat, sendMessage, pushSocketMessageToChat,

  getCategoryTreeHandler,
  getSettingsByKeyHandler,
  getSMEALLHandler,
  getSMEApprovedHandler,
  getSMERejectedHandler,
  getSMEWaitingHandler,
  getSMEFinalHandler,

  getSMECountDataHandler,

  getAllTemplatesHandler,
  getTemplatesByIDHandler,
  userRoutingHandler,
  // user admin
  userHandler,
  userPermissionHandler,

  // enterprise
  enterpriseHandler,
  getDepByEntpIDHandler,
  getManagerBYIDHandler,
  getUSERSBYManagerIDHandler,
  getUSERSBYDepIDHandler,
  subscriptionHandler,
  departmentHandler,
  managerHandler,
  categoriesHandler,
  enterpriseUserHandler,
  enterpriseUserHandlerPaginated,
  managerHandlerPaginated,

  reviewsHandler,
  getReviewsBySMEIDHandler,
  smeHandler,
  getSMERatingHandler,
  getSMECategoryHandler,

  // individual user
  getIndividualHandler,

  getSMEALLAdminTitleHandler,
  getSMEApprovedAdminTitleHandler,
  getSMEWaitingAdminTitleHandler,
  getSMEFinalAdminTitleHandler,
  getSMERejectedAdminTitleHandler,

  getSMEALLSMETitleHandler,
  getSMERejectedSMETitleHandler,
  getSMEApprovedSMETitleHandler,
  getSMEWaitingSMETitleHandler,
  getSMEFinalSMETitleHandler,
  getSMEDraftedSMETitleHandler,

  // enterprise packages
  packageEntpHandler,
  getRemarksHandler,

  getSettingsHandler,
  getRewardsHandler,
  getLanguageHandler,

  getCharityHandler,

  getUserAssignedLibrariesHandler,
  getUserListenedLibrariesHandler,
  getUserSkippedLibrariesHandler,
  getUserReadLibrariesHandler,
  getUserReviewedLibrariesHandler,

  getSubCategoriesHandler,
  getAllActiveCategoriesHandler,

  getTagsHandler,

  getFlagsHandler,
  getUserLibReviewsHandler,
  getUserLibNotesHandler,

  getSubSubCategoriesHandler,
  getAllSubSubCategoriesHandler,

  getSubSubBySMEIDHandler,

  getAllActiveScheduleHandler,
  getAllActiveScheduleManagerHandler,

  getManagerAssignedLibrariesHandler,
  getManagerReadLibrariesHandler,
  getManagerListenedLibrariesHandler,
  getManagerReviewedLibrariesHandler,
  getManagerSkippedLibrariesHandler,
  getManagerLibReviewsHandler,
  getManagerLibNotesHandler,

  getFCMSettingsHandler,

  dashboardHandler,
};
