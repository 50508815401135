// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

export const userSlice = createSlice({
  name: 'package',
  initialState: {
    packagesData: [],
    packagesName:"Individual Packages",

    resetPackage:[]
  },
  reducers: {
    packagesData: (state, action) => {
      state.packagesData = action.payload
    },
    packagesName: (state, action) => {
      state.packagesName = action.payload
    },
    resetPackage: state => {
      state.packagesData = []
      
    }
    
}
})

export const { packagesData, packagesName, resetPackage} = userSlice.actions

export default userSlice.reducer