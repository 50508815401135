// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: {
    dashboardData: {},
    resetDashboard:[]
  },
  reducers: {
    dashboardData: (state, action) => {
      state.dashboardData = action.payload
    },
    resetDashboard: state => {
      state.dashboardData = {};
     
    }


    
}
})

export const { dashboardData } = dashboardSlice.actions

export default dashboardSlice.reducer