// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit";

export const settingsSlice = createSlice({
  name: "settings",
  initialState: {
    settingsName: "Settings",
    settingsData: [],

    websiteSettingsLogo: [],
    mobileSettingsLogo: [],
    mobileSettingsInst: [],
    mobileSettingsAnn: [],

    fcmSettings: [],

    languageData: [],
    titleLogo: [],

    apiKey: {},
    emailTemplateData: [],
    reminder4Data: [], // reminder4
    mailsData: [], // mails

    resetSettings: [],
  },
  reducers: {
    settingsData: (state, action) => {
      state.settingsData = action.payload;
    },
    settingsName: (state, action) => {
      state.settingsName = action.payload;
    },
    websiteSettingsLogo: (state, action) => {
      state.websiteSettingsLogo = action.payload;
    },
    mobileSettingsLogo: (state, action) => {
      state.mobileSettingsLogo = action.payload;
    },
    mobileSettingsInst: (state, action) => {
      state.mobileSettingsInst = action.payload;
    },

    mobileSettingsAnn: (state, action) => {
      state.mobileSettingsAnn = action.payload;
    },
    AllMobileSettings: (state, action) => {
      state.AllMobileSettings = action.payload;
    },

    fcmSettings: (state, action) => {
      state.fcmSettings = action.payload;
    },

    languageData: (state, action) => {
      state.languageData = action.payload;
    },
    titleLogo: (state, action) => {
      state.titleLogo = action.payload;
    },
    apiKey: (state, action) => {
      state.apiKey = action.payload;
    },
    emailTemplateData: (state, action) => {
      // Add the reducer here
      state.emailTemplateData = action.payload;
    },
    reminder4Data: (state, action) => {
      // reminder4
      state.reminder4Data = action.payload;
    },
    mailsData: (state, action) => {
      // mails
      state.mailsData = action.payload;
    },
    resetSettings: (state) => {
      state.settingsName = "Settings";
      state.settingsData = [];
      state.websiteSettingsLogo = [];
      state.mobileSettingsLogo = [];
      state.mobileSettingsInst = [];
      state.mobileSettingsAnn = [];
      state.AllMobileSettings = [];
      state.fcmSettings = [];
      state.languageData = [];
      state.titleLogo = [];
      state.apiKey = {};
      state.emailTemplateData = [];
      state.reminder4Data = []; // reminder4
      state.mailsData = []; // mails
    },
  },
});

export const {
  apiKey,
  settingsData,
  settingsName,
  websiteSettingsLogo,
  mobileSettingsLogo,
  mobileSettingsInst,
  mobileSettingsAnn,
  languageData,
  titleLogo,
  fcmSettings,
  AllMobileSettings,
  emailTemplateData,
  reminder4Data, // reminder4
  mailsData, // mails
  resetSettings,
} = settingsSlice.actions;

export default settingsSlice.reducer;
