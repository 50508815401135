// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

// ** UseJWT import to get config
import useJwt from '@src/auth/jwt/useJwt'
import { refreshHeader } from '../@core/auth/jwt/jwtService'
// import { getHeaders } from '../auth/Utils'

const config = useJwt.jwtConfig

export const navigationSlice = createSlice({
  name: 'navigation',
  initialState: {
    LayoutRoutes: [],
    filteredNavigation:[],

    resetNavigation:[]
  },
  reducers: {
    handleNavigation: (state, action) => {
      return {
        ...state,
        LayoutRoutes: action.payload,
      };
    },
    filteredNavigation: (state, action) => {
      return {
        ...state,
        filteredNavigation: action.payload,
      };
    },
    resetNavigation: state => {
      state.LayoutRoutes = []
      state.filteredNavigation = []
      
    }
  },
});


export const { handleNavigation, filteredNavigation, resetNavigation } = navigationSlice.actions

export default navigationSlice.reducer