// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

export const rewardsSlice = createSlice({
  name: 'rewards',
  initialState: {
    rewardsData: [],

    resetRewards:[]
  },
  reducers: {
    rewardsData: (state, action) => {
      state.rewardsData = action.payload
    },
    resetRewards: state => {
      state.rewardsData = [];
      
    }
   


    
}
})

export const { rewardsData, resetRewards } = rewardsSlice.actions

export default rewardsSlice.reducer