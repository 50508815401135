// // ** Redux Imports
// import { createSlice } from '@reduxjs/toolkit'

// export const userScheduleSlice = createSlice({
//   name: 'allActiveSchedule',
//   initialState: {
//     setUserScheduleData: [],
//   },
//   reducers: {
//     setUserScheduleData: (state, action) => {
//       state.setUserScheduleData = action.payload
//     },
//   },
// })

// export const { setUserScheduleData } = userScheduleSlice.actions

// export default userScheduleSlice.reducer
// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

export const allActiveScheduleSlice = createSlice({
  name: 'allActiveSchedule',
  initialState: {
    allActiveScheduleData:[], 
    allActiveScheduleManagerData:[], 

    resetSchedule:[]
  },
  reducers: {
    
    allActiveScheduleData: (state, action) => {
      state.allActiveScheduleData = action.payload
    },
    allActiveScheduleManagerData: (state, action) => {
      state.allActiveScheduleManagerData = action.payload
    },
    resetSchedule: state => {
      state.allActiveScheduleData = [];
      state.allActiveScheduleManagerData = [];
     
    }
    
}
})

export const { allActiveScheduleData, allActiveScheduleManagerData, resetSchedule  } = allActiveScheduleSlice.actions

export default allActiveScheduleSlice.reducer