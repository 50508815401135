// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

export const smeAdminTitleSlice = createSlice({
  name: 'reduxForSMETitle',
  initialState: {
    smeAllTitle: [],
    smeApprovedTitle: [],
    smeWaitingTitle:[],
    smeFinalTitle:[],
    smeRejectedTitle:[],
    smeAllName:"SME Tutorials",
    smeApprovedName:"Approved SME Tutorial",
    smeWaitingName:"Technical SME Review",
    smeFinalName:"Final SME Review",
    smeRejectedName:"Rejected SME Tutorial",

    resetAdminSMETitle:[]
  },
  reducers: {
    smeAllTitle: (state, action) => {
      state.smeAllTitle = action.payload
    },
    smeApprovedTitle: (state, action) => {
      state.smeApprovedTitle = action.payload
    },
    smeWaitingTitle: (state, action) => {
      state.smeWaitingTitle = action.payload
    },
    smeFinalTitle: (state, action) => {
      state.smeFinalTitle = action.payload
    },
    smeRejectedTitle: (state, action) => {
      state.smeRejectedTitle = action.payload
    },

    smeAllName: (state, action) => {
      state.smeAllName = action.payload
    },
    smeApprovedName: (state, action) => {
      state.smeApprovedName = action.payload
    },
    smeWaitingName: (state, action) => {
      state.smeWaitingName = action.payload
    },
    smeFinalName: (state, action) => {
      state.smeFinalName = action.payload
    },
    smeRejectedName: (state, action) => {
      state.smeRejectedName = action.payload
    },
    resetAdminSMETitle: state => {
      state.smeAllTitle = [];
      state.smeApprovedTitle = [];
      state.smeWaitingTitle = [];
      state.smeFinalTitle = [];
      state.smeRejectedTitle = [];
      state.smeAllName = "SME Tutorials";
      state.smeApprovedName = "Approved SME Tutorial";
      state.smeWaitingName = "Technical SME Review";
      state.smeFinalName = "Final SME Review";
      state.smeRejectedName = "Rejected SME Tutorial";
     
    }
    
}
})

export const { smeAllTitle, smeAllName, smeApprovedTitle, smeRejectedTitle, 
    smeWaitingTitle, smeApprovedName , smeWaitingName , smeRejectedName,
    smeFinalTitle, smeFinalName, resetAdminSMETitle
} = smeAdminTitleSlice.actions

export default smeAdminTitleSlice.reducer