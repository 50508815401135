// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "manager",
  initialState: {
    managerData: [],
    managerName: "Manager",
    totalRowsManager: 0,
    resetManager: [],
  },
  reducers: {
    managerData: (state, action) => {
      console.log("Datta ");
      state.managerData = action.payload;
    },
    managerName: (state, action) => {
      state.managerName = action.payload;
    },
    resetManager: (state) => {
      state.managerData = [];
    },
    setTotalRowsManager: (state, action) => {
      console.log("Setting Total Rows manager:", action.payload);
      state.totalRowsManager = action.payload; // Update total rows for pagination
    },
  },
});

export const { managerData, managerName, resetManager, setTotalRowsManager } =
  userSlice.actions;

export default userSlice.reducer;
