// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

export const notificationSlice = createSlice({
  name: 'notification',
  initialState: {
    notificationLimitData: [],
    notificationAllData: [],
    notificationSeen: '',
    resetNotification:[]
  },
  reducers: {
    notificationLimitData: (state, action) => {
      state.notificationLimitData = action.payload
    },
    notificationAllData: (state, action) => {
      state.notificationAllData = action.payload
    },
    notificationSeen: (state, action) => {
      state.notificationSeen = action.payload
    },
   
    resetNotification: state => {
      state.notificationLimitData = []
      state.notificationAllData = []
      state.notificationSeen = []
      
    }
    
}
})

export const { notificationLimitData, notificationAllData, notificationSeen, resetNotification} = notificationSlice.actions

export default notificationSlice.reducer