// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

export const indvSlice = createSlice({
  name: 'individual',
  initialState: {
    individualUserData: [],
    resetIndv:[]
    
  },
  reducers: {
    individualUserData: (state, action) => {
      state.individualUserData = action.payload
    },
    resetIndv: state => {
      state.individualUserData = []
      
    }
    
    
}
})

export const { individualUserData, resetIndv } = indvSlice.actions

export default indvSlice.reducer