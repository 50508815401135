// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

export const packageEntpSlice = createSlice({
  name: 'packageentp',
  initialState: {
    packageEntpData: [],
    packageEntpName:"Enterprise Packages",

    resetPackageEntp:[]
  },
  reducers: {
    packageEntpData: (state, action) => {
      state.packageEntpData = action.payload
    },
    packageEntpName: (state, action) => {
      state.packageEntpName = action.payload
    },
    resetPackageEntp: state => {
      state.packageEntpData = [];
      
    }
    
}
})

export const { packageEntpData, packageEntpName, resetPackageEntp } = packageEntpSlice.actions

export default packageEntpSlice.reducer