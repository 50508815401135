// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

export const smeSMETitleSlice = createSlice({
  name: 'smeSMETitle',
  initialState: {
    smeAllSMETitleData: [],
    smeAllSMETitleName:"SME Tutorial",
    smeApprovedSMETitleData: [],
    smeApprovedSMETitleName:"SME Approved Tutorial",
    smeWaitingSMETitleData:[],
    smeWaitingSMETitleName:"SME Technical Review",
    
    smeFinalSMETitleData:[],
    smeFinalSMETitleName:"SME Final Review",

    smeRejectedSMETitleData:[],
    smeRejectedSMETitleName:"SME Rejected Tutorial",
    smeDraftedSMETitleData:[],
    smeDraftedSMETitleName:"SME Drafted Tutorial",

    // ADD SME TITLE
    addSMETitleData:[],
    addSMETitleName:'Add SME Tutorial',
    smeRemarks:[],

    smesmeCount:[],


    resetSMETitle:[]

  },
  reducers: {
    smeAllSMETitleData: (state, action) => {
      state.smeAllSMETitleData = action.payload
    },
    smeApprovedSMETitleData: (state, action) => {
      state.smeApprovedSMETitleData = action.payload
    },
    smeWaitingSMETitleData: (state, action) => {
      state.smeWaitingSMETitleData = action.payload
    },
    smeFinalSMETitleData: (state, action) => {
      state.smeFinalSMETitleData = action.payload
    },
    smeRejectedSMETitleData: (state, action) => {
      state.smeRejectedSMETitleData = action.payload
    },
    smeDraftedSMETitleData: (state, action) => {
      state.smeDraftedSMETitleData = action.payload
    },

    smeAllSMETitleName: (state, action) => {
      state.smeAllSMETitleName = action.payload
    },
    smeApprovedSMETitleName: (state, action) => {
      state.smeApprovedSMETitleName = action.payload
    },
    smeWaitingSMETitleName: (state, action) => {
      state.smeWaitingSMETitleName = action.payload
    },
    smeFinalSMETitleName: (state, action) => {
      state.smeFinalSMETitleName = action.payload
    },
    smeRejectedSMETitleName: (state, action) => {
      state.smeRejectedSMETitleName = action.payload
    },
    smeDraftedSMETitleName: (state, action) => {
      state.smeDraftedSMETitleName = action.payload
    },
    smeRemarks: (state, action) => {
      state.smeRemarks = action.payload
    },
    
    // ADD SME TITLE
    addSMETitleData: (state, action) => {
      state.addSMETitleData = action.payload
    },
    addSMETitleName: (state, action) => {
      state.addSMETitleName = action.payload
    },
    smesmeCount: (state, action) => {
      state.smesmeCount = action.payload
    },
    resetSMETitle: state => {
       // Reset SME SME title data
    state.smeAllSMETitleData = [];
    state.smeAllSMETitleName = "SME Tutorial";
    state.smeApprovedSMETitleData = [];
    state.smeApprovedSMETitleName = "SME Approved Tutorial";
    state.smeWaitingSMETitleData = [];
    state.smeWaitingSMETitleName = "SME Technical Review";
    state.smeFinalSMETitleData = [];
    state.smeFinalSMETitleName = "SME Final Review";
    state.smeRejectedSMETitleData = [];
    state.smeRejectedSMETitleName = "SME Rejected Tutorial";
    state.smeDraftedSMETitleData = [];
    state.smeDraftedSMETitleName = "SME Drafted Tutorial";

    // Reset SME Add title data
    state.addSMETitleData = [];
    state.addSMETitleName = 'Add SME Tutorial';
    state.smeRemarks = [];
    state.smesmeCount = [];
    }



}
})

export const { smeAllSMETitleData, smeAllSMETitleName, smeApprovedSMETitleData, smeRejectedSMETitleData, 
    smeWaitingSMETitleData, smeApprovedSMETitleName , smeWaitingSMETitleName , smeRejectedSMETitleName,

    smeDraftedSMETitleData, smeDraftedSMETitleName, 
    
    addSMETitleData, addSMETitleName, smeRemarks,

    smeFinalSMETitleData, smeFinalSMETitleName,
    
    smesmeCount, resetSMETitle

} = smeSMETitleSlice.actions

export default smeSMETitleSlice.reducer