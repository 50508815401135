// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

export const tokenSlice = createSlice({
  name: 'verifyToken',
  initialState: {
    verifyTokenValue: '',
    entpVerify : ''


  },
  reducers: {
    verifyTokenValue: (state, action) => {
      state.verifyTokenValue = action.payload
    },
    entpVerify: (state, action) => {
      state.entpVerify = action.payload
    },
    
}
})

export const { verifyTokenValue, entpVerify } = tokenSlice.actions

export default tokenSlice.reducer