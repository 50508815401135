// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

export const managerLibSlice = createSlice({
  name: 'managerLibraries',
  initialState: {
    managerAssignedLibrariesData: [],
    managerReadLibrariesData: [],
    managerListenedLibrariesData: [],
    managerReviewedLibrariesData: [],
    managerSkippedLibrariesData: [],

    managerLibReviews : [],
    managerLibSME : [],
    managerLibNotes:[],
    userNotes:[],
    managerNotes:[],

    managerLibPagination:{
      page:1,
      pageSize:10,
      search_keyword:"",
      filter:"assigned",
      totalPages:"",
      total_rows:"",
      user_id:'',
      type:'manager',
    },

    resetManagerLibrary:[]
  },
  reducers: {
    managerAssignedLibrariesData: (state, action) => {
      state.managerAssignedLibrariesData = action.payload
    },
    managerReadLibrariesData: (state, action) => {
      state.managerReadLibrariesData = action.payload
    },
    managerListenedLibrariesData: (state, action) => {
      state.managerListenedLibrariesData = action.payload
    },
    managerReviewedLibrariesData: (state, action) => {
      state.managerReviewedLibrariesData = action.payload
    },
    managerSkippedLibrariesData: (state, action) => {
      state.managerSkippedLibrariesData = action.payload
    },
    managerLibReviews: (state, action) => {
      state.managerLibReviews = action.payload
    },
    managerLibSME: (state, action) => {
      state.managerLibSME = action.payload
    },
    managerLibNotes: (state, action) => {
      state.managerLibNotes = action.payload
    },
    userNotes: (state, action) => {
      state.userNotes = action.payload
    },
    managerNotes: (state, action) => {
      state.managerNotes = action.payload
    },
    managerLibPagination: (state, action) => {
      state.managerLibPagination = action.payload
    },
    resetManagerLibrary: state => {
      state.managerAssignedLibrariesData = [];
    state.managerReadLibrariesData = [];
    state.managerListenedLibrariesData = [];
    state.managerReviewedLibrariesData = [];
    state.managerSkippedLibrariesData = [];
    state.managerLibReviews = [];
    state.managerLibSME = [];
    state.managerLibNotes = [];
    state.userNotes = [];
    state.managerNotes = [];
     
    }
   


    
}
})

export const { managerAssignedLibrariesData, managerReadLibrariesData, managerListenedLibrariesData,
  managerReviewedLibrariesData, managerSkippedLibrariesData, 
  managerLibReviews, managerLibNotes, managerLibPagination, managerLibSME,
  userNotes, managerNotes, resetManagerLibrary
 } = managerLibSlice.actions

export default managerLibSlice.reducer