// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

export const tagsSlice = createSlice({
  name: 'tags',
  initialState: {
    tagsData: [],
    flagsData: [],

    resetTags:[]
  },
  reducers: {
    tagsData: (state, action) => {
      state.tagsData = action.payload
    },
    flagsData: (state, action) => {
      state.flagsData = action.payload
    },
    resetTags: state => {
      state.tagsData = [];
      state.flagsData = [];
     
    }


    
}
})

export const { tagsData, flagsData, resetTags } = tagsSlice.actions

export default tagsSlice.reducer