// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

export const userSlice = createSlice({
  name: 'department',
  initialState: {
    departmentData: [],
    departmentName:"Department"
  },
  reducers: {
    departmentData: (state, action) => {
      state.departmentData = action.payload
    },
    departmentName: (state, action) => {
      state.departmentName = action.payload
    },
    
}
})

export const { departmentData, departmentName, subscriptionData} = userSlice.actions

export default userSlice.reducer