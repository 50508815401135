// ** Reducers Imports
import layout from "./layout"
import navbar from "./navbar"

import auth from "./authentication"
import userRedux from "./userRedux"
import nav from './navigation'
import smeRedux from "./smeRedux"
import packageRedux from "./packageRedux"
import TopicsKeysRedux from "./TopicsKeysRedux"
import reviewsRedux from "./reviewsRedux"
import enterpriseRedux from "./enterpriseRedux"
import departmentRedux from "./departmentRedux"
import managerRedux from "./managerRedux"
import enterpriseUserRedux from "./enterpriseUserRedux"
import extraRedux from "./extraRedux"
import individualRedux from "./individualRedux"
import smeAdminTitleRedux from "./smeAdminTitleRedux"
import smeSMETitleRedux from "./smeSMETitleRedux"
import packageEntpRedux from "./packageEntpRedux"
import settingsredux from "./settingsredux"
import rewardsRedux from "./rewardsRedux"
import charityRedux from "./charityRedux"
import userLibrariesRedux from "./userLibrariesRedux"
import AllActiveCategoryRedux from "./AllActiveCategoryRedux"
import tagsRedux from "./tagsRedux"
import Scheduleredux from "./Scheduleredux"
import templatesRedux from "./templatesRedux"
import ReduxForSMETitle from "./ReduxForSMETitle"
import managerLibRedux from "./managerLibRedux"
import VerifyTokenRedux from "./VerifyTokenRedux"
import chatRedux from "./chatRedux"
import notificationRedux from "./notificationRedux"

import dashboardRedux from './dashboardRedux'

const rootReducer = { 
    navbar, 
    layout,
    auth,
    nav,
    VerifyTokenRedux,
    chatRedux,
    notificationRedux,

    userRedux,
    smeRedux,
    packageRedux,
    TopicsKeysRedux,
    reviewsRedux,
    enterpriseRedux,
    departmentRedux,
    managerRedux,
    enterpriseUserRedux,
    extraRedux,
    individualRedux,
    smeAdminTitleRedux,
    smeSMETitleRedux,
    packageEntpRedux,
    settingsredux,
    rewardsRedux,
    charityRedux,
    userLibrariesRedux,
    AllActiveCategoryRedux,
    tagsRedux,
    Scheduleredux, 
    templatesRedux,
    ReduxForSMETitle,
    managerLibRedux,
    dashboardRedux



 }

export default rootReducer
