// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

export const smeAdminTitleSlice = createSlice({
  name: 'smeAdminTitle',
  initialState: {
    smeAllAdminTitleData: [],
    smeApprovedAdminTitleData: [],
    smeWaitingAdminTitleData:[],
    smeFinalAdminTitleData:[],
    smeRejectedAdminTitleData:[],
    smeAllAdminTitleName:"SME Admin Tutorial",
    smeApprovedAdminTitleName:"SME Admin Approved Tutorial",
    smeWaitingAdminTitleName:"Technical Review",
    smeFinalAdminTitleName:"Final Review",
    smeRejectedAdminTitleName:"SME Admin Rejected Tutorial",

    tutorialStatus:'',

    adminAllTutorialPagination:{
      page:1,
      pageSize:10,
      search_keyword:"",
      filter:"all",
      totalPages:"",
      total_rows:"",
    },
    

    resetAdminTitle:[]
  },
  reducers: {
    smeAllAdminTitleData: (state, action) => {
      state.smeAllAdminTitleData = action.payload
    },
    smeApprovedAdminTitleData: (state, action) => {
      state.smeApprovedAdminTitleData = action.payload
    },
    smeWaitingAdminTitleData: (state, action) => {
      state.smeWaitingAdminTitleData = action.payload
    },
    smeFinalAdminTitleData: (state, action) => {
      state.smeFinalAdminTitleData = action.payload
    },
    smeRejectedAdminTitleData: (state, action) => {
      state.smeRejectedAdminTitleData = action.payload
    },

    smeAllAdminTitleName: (state, action) => {
      state.smeAllAdminTitleName = action.payload
    },
    smeApprovedAdminTitleName: (state, action) => {
      state.smeApprovedAdminTitleName = action.payload
    },
    smeWaitingAdminTitleName: (state, action) => {
      state.smeWaitingAdminTitleName = action.payload
    },
    smeFinalAdminTitleName: (state, action) => {
      state.smeFinalAdminTitleName = action.payload
    },
    smeRejectedAdminTitleName: (state, action) => {
      state.smeRejectedAdminTitleName = action.payload
    },
    adminAllTutorialPagination: (state, action) => {
      state.adminAllTutorialPagination = action.payload
    },

    resetAdminTitle: state => {
      state.smeAllAdminTitleData = [];
      state.smeApprovedAdminTitleData = [];
      state.smeWaitingAdminTitleData = [];
      state.smeFinalAdminTitleData = [];
      state.smeRejectedAdminTitleData = [];
      state.adminAllTutorialPagination = {};
      state.smeAllAdminTitleName = "SME Admin Tutorial";
      state.smeApprovedAdminTitleName = "SME Admin Approved Tutorial";
      state.smeWaitingAdminTitleName = "Technical Review";
      state.smeFinalAdminTitleName = "Final Review";
      state.smeRejectedAdminTitleName = "SME Admin Rejected Tutorial";
    }
    
}
})

export const { smeAllAdminTitleData, smeAllAdminTitleName, smeApprovedAdminTitleData, smeRejectedAdminTitleData, 
    smeWaitingAdminTitleData, smeApprovedAdminTitleName , smeWaitingAdminTitleName , smeRejectedAdminTitleName,
    smeFinalAdminTitleData, smeFinalAdminTitleName, resetAdminTitle, adminAllTutorialPagination
} = smeAdminTitleSlice.actions

export default smeAdminTitleSlice.reducer