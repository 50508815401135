import { Ability } from '@casl/ability'
import { getNavigation } from '../../navigation/vertical'
import { store } from '../../redux/store'
import { initialAbility } from './initialAbility'
import { filteredNavigation, handleNavigation } from '../../redux/navigation'

//  Read ability from localStorage
// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
// ! Anyone can update localStorage so be careful and please update this
const userData = JSON.parse(localStorage.getItem('userData'))
const existingAbility = userData ? userData.ability : null
// const nav = 
const navigationCall=async ()=>{

    if(userData!=null){
        const navigation =  await getNavigation()
        store.dispatch(filteredNavigation(navigation))
        store.dispatch(handleNavigation(navigation))
    }
}

navigationCall();


export default new Ability(existingAbility || initialAbility)
