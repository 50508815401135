// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

export const userLibSlice = createSlice({
  name: 'userLibraries',
  initialState: {
    userAssignedLibrariesData: [],
    userReadLibrariesData: [],
    userListenedLibrariesData: [],
    userReviewedLibrariesData: [],
    userSkippedLibrariesData: [],

    userLibReviews : [],
    userLibSME : [],


    userLibNotes:[],
    userNotes:[],
    managerNotes:[],

    resetUserLibrary:[],
    
    userLibPagination:{
      page:1,
      pageSize:10,
      search_keyword:"",
      filter:"assigned",
      totalPages:"",
      total_rows:"",
      user_id:'',
      type:'user',
    },
  },
  reducers: {
    userAssignedLibrariesData: (state, action) => {
      state.userAssignedLibrariesData = action.payload
    },
    userReadLibrariesData: (state, action) => {
      state.userReadLibrariesData = action.payload
    },
    userListenedLibrariesData: (state, action) => {
      state.userListenedLibrariesData = action.payload
    },
    userReviewedLibrariesData: (state, action) => {
      state.userReviewedLibrariesData = action.payload
    },
    userSkippedLibrariesData: (state, action) => {
      state.userSkippedLibrariesData = action.payload
    },
    userLibReviews: (state, action) => {
      state.userLibReviews = action.payload
    },
    userLibSME: (state, action) => {
      state.userLibSME = action.payload
    },
    userLibNotes: (state, action) => {
      state.userLibNotes = action.payload
    },
    userNotes: (state, action) => {
      state.userNotes = action.payload
    },
    managerNotes: (state, action) => {
      state.managerNotes = action.payload
    },
    userLibPagination: (state, action) => {
      state.userLibPagination = action.payload
    },
    resetUserLibrary: state => {
      state.userAssignedLibrariesData = [];
      state.userReadLibrariesData = [];
      state.userListenedLibrariesData = [];
      state.userReviewedLibrariesData = [];
      state.userSkippedLibrariesData = [];
      state.userLibReviews = [];
      state.userLibSME = [];
      state.userLibNotes = [];
      state.userNotes = [];
      state.managerNotes = [];
      state.userLibPagination = {};
  },
   


    
}
})

export const { userAssignedLibrariesData, userReadLibrariesData, userListenedLibrariesData,
  userReviewedLibrariesData, userSkippedLibrariesData, 
  userLibReviews, userLibNotes, userLibSME,
  userNotes, managerNotes, resetUserLibrary, userLibPagination
 } = userLibSlice.actions

export default userLibSlice.reducer