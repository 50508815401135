// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

export const allActiveCategorySlice = createSlice({
  name: 'allActiveCategory',
  initialState: {
    allActiveCategoriesData:[], 

    resetAllActiveCat:[]
  },
  reducers: {
    
    allActiveCategoriesData: (state, action) => {
      state.allActiveCategoriesData = action.payload
    },
    resetAllActiveCat: state => {
      state.allActiveCategoriesData = [];
     
    }
    
}
})

export const { allActiveCategoriesData, resetAllActiveCat } = allActiveCategorySlice.actions

export default allActiveCategorySlice.reducer