// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

export const userSlice = createSlice({
  name: 'extra',
  initialState: {
    reviewsName:"Reviews", 
    modalName:'',
    categoryTree:[],
    categoryTreeName:'',

    resetExtra:[]
  },
  reducers: {
    
    reviewsName: (state, action) => {
      state.reviewsName = action.payload
    },
    modalName: (state, action) => {
      state.modalName = action.payload
    },
    categoryTree: (state, action) => {
      state.categoryTree = action.payload
    },
    categoryTreeName: (state, action) => {
      state.categoryTreeName = action.payload
    },
    resetExtra: state => {
      state.categoryTree = []
      state.modalName = ''
      state.categoryTreeName = ''
    }
    
}
})

export const { reviewsName, modalName, categoryTree, categoryTreeName, resetExtra} = userSlice.actions

export default userSlice.reducer