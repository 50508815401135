// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

export const userSlice = createSlice({
  name: 'sme',
  initialState: {
    smeData: [],
    smeCategoryData: [],
    SmeName:"SME",
    smeCategoryName:"Main Category",
    averageRatingData:[],

    subCategoryData:[],


    subSubCatData:[],
    allSubSubCatData:[],

    resetSME:[]

    

  },
  reducers: {
    smeData: (state, action) => {
      state.smeData = action.payload
    },
    mainSmeName: (state, action) => {
      state.SmeName = action.payload
    },
    
    smeCategoryData: (state, action) => {
      state.smeCategoryData = action.payload
    },
    smeCategoryName : (state, action) => {
      state.smeCategoryName = action.payload
    },
    averageRatingData : (state, action) => {
      state.averageRatingData = action.payload
    },
   
    subCategoryData : (state, action) => {
      state.subCategoryData = action.payload
    },
    subSubCatData : (state, action) => {
      state.subSubCatData = action.payload
    },
    allSubSubCatData : (state, action) => {
      state.allSubSubCatData = action.payload
    },
   
    resetSME: state => {
      state.smeData = []
      state.smeCategoryData = []
      state.averageRatingData = []
      state.subCategoryData = []
      state.subSubCatData = []
      state.allSubSubCatData = []
    }


}
})

export const {resetSME, smeData, mainSmeName, smeCategoryData, smeCategoryName, averageRatingData, subCategoryData, subSubCatData, allSubSubCatData } = userSlice.actions

export default userSlice.reducer