// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

// ** UseJWT import to get config
import useJwt from '@src/auth/jwt/useJwt'
import { refreshHeader } from '../@core/auth/jwt/jwtService'

const config = useJwt.jwtConfig

const initialUser = () => {
  const item = window.localStorage.getItem('userData')
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : {}
}

export const authSlice = createSlice({
  name: 'authentication',
  initialState: {
    userData: initialUser()
  },
  reducers: {
    handleLogin: (state, action) => {
      state.userData = action.payload
      state[config.storageTokenKeyName] = action.payload[config.storageTokenKeyName]
      // state[config.storageRefreshTokenKeyName] = action.payload[config.storageRefreshTokenKeyName]
      localStorage.setItem('userData', JSON.stringify(action.payload))

      // console.log('CALLED')
      localStorage.setItem(config.storageTokenKeyName, JSON.stringify(action.payload.Token))
      // localStorage.setItem(config.storageRefreshTokenKeyName, JSON.stringify(action.payload.refreshToken))
      refreshHeader()
    },  
    handleLogout: state => {
      state.userData = {}
      state[config.storageTokenKeyName] = null
      state[config.storageRefreshTokenKeyName] = null
      // ** Remove user, accessToken & refreshToken from localStorage
      localStorage.removeItem('userData')
      localStorage.removeItem(config.storageTokenKeyName)
      // localStorage.removeItem(config.storageRefreshTokenKeyName)
      localStorage.removeItem('navigation')
      localStorage.removeItem('navLinks')
      localStorage.removeItem('SME')
    },
    handleSocket:(state,action)=>{
      
      const connection = action.payload.connection
      const socket = action.payload.socket

      console.log(' $ $ PAYLOAD PAYLOADPAYLOADPAYLOADPAYLOADPAYLOAD :  ',action.payload)
      
      if(connection){
      
        localStorage.setItem('socketConnected',connection)
      
      }else{

        localStorage.removeItem('socketConnected')

      }

      state.socket = socket
    }
}
})

export const { handleLogin, handleLogout, handleSocket } = authSlice.actions

export default authSlice.reducer