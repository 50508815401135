
// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

export const charitySlice = createSlice({
  name: 'charity',
  initialState: {
    charityData: [],
    charityName:"Department",

    resetCharity:[]
  },
  reducers: {
    charityData: (state, action) => {
      state.charityData = action.payload
    },
    
    charityName: (state, action) => {
      state.charityName = action.payload
    },

    resetCharity: state => {
      state.charityData = [];
      
    }
    
}
})

export const { charityData, charityName, resetCharity} = charitySlice.actions

export default charitySlice.reducer