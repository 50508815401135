// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

export const chatSlice = createSlice({
  name: 'chat',
  initialState: {
    allChats: {},
    usersList:[],
    currentChat:[]

  },
  reducers: {
    allChats: (state, action) => {
      state.allChats = action.payload
    },
    usersList: state => {
      state.usersList = action.payload
    },
    currentChat: state => {
      state.currentChat = action.payload
    }
   


    
}
})

export const { allChats, usersList, currentChat } = chatSlice.actions

export default chatSlice.reducer