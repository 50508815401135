// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

export const templateSlice = createSlice({
  name: 'templates',
  initialState: {
    templatesAll: [],
    templatesByID:[],

    resetTemplates:[]
  },
  reducers: {
    templatesAll: (state, action) => {
      state.templatesAll = action.payload
    },
    templatesByID: (state, action) => {
      state.templatesByID = action.payload
    },
    resetTemplates: state => {
      state.templatesAll = [];
      state.templatesByID = [];
     
    }
    
}
})

export const { templatesAll, templatesByID, resetTemplates } = templateSlice.actions

export default templateSlice.reducer