// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "enterpriseUser",
  initialState: {
    enterpriseUserData: [],
    enterpriseUserName: "Enterprise User",
    totalRows: 0,
    resetEntpUser: [],
  },
  reducers: {
    enterpriseUserData: (state, action) => {
      state.enterpriseUserData = action.payload;
    },
    enterpriseUserName: (state, action) => {
      state.enterpriseUserName = action.payload;
    },
    resetEntpUser: (state) => {
      state.enterpriseUserData = [];
    },
    setTotalRows: (state, action) => {
      console.log("Setting Total Rows:", action.payload);
      state.totalRows = action.payload; // Update total rows for pagination
    },
  },
});

export const {
  enterpriseUserData,
  enterpriseUserName,
  resetEntpUser,
  setTotalRows,
} = userSlice.actions;

export default userSlice.reducer;
